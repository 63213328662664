import styled from 'styled-components';

interface PageContentProps {
  fullWidth?: boolean;
}

export const PageContent = styled.div<PageContentProps>`
  width: 100%;
  max-width: ${props => !props.fullWidth && '1250px'};
  padding: 50px 30px 70px;

`;

export const Page = styled.div`
  display: flex;
  justify-content: center;
`;
