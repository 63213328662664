import { AxiosError } from 'axios';
import isString from 'lodash-es/isString';

import { ServiceError } from 'contracts/types/response';

import translate from './translate';

const formatServiceError = (
  error: AxiosError<ServiceError>,
): string => {
  let message = translate('core.anErrorOccurredLoadingThisPage');
  if (error && error.response) {
    const status = error.response.status;
    if (status === 401) {
      message = translate('core.youAreNotAuthorizedToViewThisPage');
    } else if (status === 400 || status === 422) {
      message = (isString(error.response.data) && error.response.data) ? error.response.data : (error.response.data.exceptionMessage || error.response.data.message || translate('core.anErrorOccurredLoadingThisPage'));
    } else {
      message = (isString(error.response.data) && error.response.data) ? error.response.data : (error.response.data.message || translate('core.anErrorOccurredLoadingThisPage'));
    }
  }
  return message;
};

export default formatServiceError;
