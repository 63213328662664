/* eslint-disable no-console */
import { AxiosError } from 'axios';

import { Environments } from 'contracts/enums';
import { ServiceError } from 'contracts/types/response';

// import { environment } from 'core/services/environment';
// temporary to debug on prod
const environment: Environments = Environments.development;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logError(err: any, logAxiosData = true): void {
  if (err && environment !== Environments.production) {
    if (logAxiosData && err.isAxiosError) {
      const axiosError = err as AxiosError<ServiceError>;
      console.error(axiosError.toJSON());
    } else {
      console.error(err);
    }
  }
}

export function logWarning(msg: string): void {
  if (msg && environment !== Environments.production) {
    console.log(msg);
  }
}

export function logMessage(msg: string): void {
  if (msg && environment !== Environments.production) {
    console.info(msg);
  }
}
