import { get, set, remove } from 'local-storage';

import { SavingsUserDataType } from 'contracts/types/form';
import { CartState } from 'contracts/types/state';

enum LocalStorageKey {
  authToken = 'rubiconCheckoutAuthToken',
  savingsUserData = 'savingsUserData',
  cart = 'rubiconCheckoutCart',
}

const getSessionItem = <T>(key: LocalStorageKey): T => get<T>(key);

const setSessionItem = <T>(key: LocalStorageKey, value: T): boolean => set<T>(key, value);

export const getSessionAuthToken = (): string => getSessionItem(LocalStorageKey.authToken);
export const getSavingsUserData = (): SavingsUserDataType => getSessionItem(LocalStorageKey.savingsUserData);

export const setSessionAuthToken = (authToken: string): void => {
  if (authToken) {
    set(LocalStorageKey.authToken, authToken);
  } else {
    remove(LocalStorageKey.authToken);
  }
};

export const setSavingsUserData = (data?: SavingsUserDataType): void => {
  if (data) {
    setSessionItem(LocalStorageKey.savingsUserData, data);
  } else {
    remove(LocalStorageKey.savingsUserData);
  }
};

export const destroySession = (): void => {
  remove(LocalStorageKey.authToken);
  remove(LocalStorageKey.cart);
  remove(LocalStorageKey.savingsUserData);
};

export function getSessionCart(): CartState {
  let cart: CartState = getSessionItem(LocalStorageKey.cart);
  if (!cart) {
    cart = {
      container: {},
      address: {},
      checkout: {},
      payment: {},
    } as CartState;
    setSessionCart(cart);
  }
  return cart;
}

export function setSessionCart(cart: CartState): boolean {
  return setSessionItem(LocalStorageKey.cart, cart);
}
