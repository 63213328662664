import { transparentize, setLightness, setSaturation } from 'polished';
import styled from 'styled-components';

import { fadeAndSlideInUp, theme } from 'core/styles';
import colorMapper, { ColorProps } from 'utils/styles/colorMapper';

import Icon from '../Icon';

import { BareButtonStyle } from './Button';

const createNotificationBackgroundColor = (color: string): string => setSaturation(0.2, setLightness(0.96, color));
const createNotificationBorderColor = (color: string): string => setSaturation(0.3, setLightness(0.82, color));

export const NotificationCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 8px;
  height: 8px;
  margin-top: -6px;
  vertical-align: middle;
  color: ${theme.colors.grayDark};
`;

export const NotificationClose = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  position: absolute;
  top: 8px;
  right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  color: #fff;
  background-color: transparent;
`;

export const NotificationIconContainer = styled.div`
  top: 3px;
  left: 12px;
  position: absolute;
  height: 100%;
  width: 27px;
  display: flex;
  align-items: center;
`;

export const NotificationSuccessIcon = styled(Icon).attrs({ icon: 'success' })`
  position: absolute;
  
  width: 27px;
  height: 27px;
  margin-top: -6px;
  vertical-align: middle;
  color: ${theme.colors.brandSuccess};
`;

export const NotificationWarningIcon = styled(Icon).attrs({ icon: 'warning' })`
  position: absolute;
  
  width: 27px;
  height: 27px;
  margin-top: -6px;
  vertical-align: middle;
  color: ${theme.colors.brandWarning};
`;

export const Notification = styled.div<ColorProps>`
  position: relative;
  min-width: 330px;
  margin-bottom: 20px;
  padding: 15px 35px 18px 15px;
  background-color: ${props => props.color ? createNotificationBackgroundColor(colorMapper(props)) : '#fff'};
  border-radius: 4px;
  border: 1px solid ${props => createNotificationBorderColor(colorMapper(props))};
  box-shadow: 0 6px 16px ${transparentize(0.8, '#000')};
  line-height: 20px;
  font-size: 15px;
  color: ${props => colorMapper(props)};
  animation: 0.3s ease-out ${fadeAndSlideInUp};
`;

export const NotificationList = styled.div`
  position: fixed;
  top: 16px;
  right: 10px;
  z-index: 7000;
`;
