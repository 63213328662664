import { combineReducers } from 'redux';

import { reducer as notifierReducer } from './notifier';
import { reducer as runningReducer } from './running';

const coreReducer = combineReducers({
  notifier: notifierReducer,
  running: runningReducer,
});

export default coreReducer;
