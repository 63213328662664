enum FieldErrorType {
  required = 'required',
  minLength = 'minLength',
  maxLength = 'maxLength',
  min = 'min',
  max = 'max',
  pattern = 'pattern',
  validate = 'validate',
}
export default FieldErrorType;
