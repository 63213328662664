import React, { SVGProps } from 'react';

const renderIcon = (icon: string): JSX.Element | null => {
  switch (icon) {
    
    case 'quote':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M19.118 11.154h-.824c-.227 0-.412-.172-.412-.385 0-.212.185-.384.412-.384h.824c.226 0 .411.172.411.384 0 .213-.185.385-.411.385m0 2.308h-.824c-.227 0-.412-.173-.412-.385 0-.212.185-.385.412-.385h.824c.226 0 .411.173.411.385 0 .212-.185.385-.411.385m0 2.307h-.824c-.227 0-.412-.172-.412-.384 0-.213.185-.385.412-.385h.824c.226 0 .411.172.411.385 0 .212-.185.384-.411.384m0 2.308h-.824c-.227 0-.412-.172-.412-.385 0-.212.185-.384.412-.384h.824c.226 0 .411.172.411.384 0 .213-.185.385-.411.385m-3.294-6.923h-4.942c-.227 0-.411-.172-.411-.385 0-.212.184-.384.411-.384h4.942c.226 0 .411.172.411.384 0 .213-.185.385-.411.385m-5.353 1.923c0-.212.184-.385.411-.385h3.294c.227 0 .412.173.412.385 0 .212-.185.385-.412.385h-3.294c-.227 0-.411-.173-.411-.385M15 15.769h-4.118c-.227 0-.411-.172-.411-.384 0-.213.184-.385.411-.385H15c.226 0 .412.172.412.385 0 .212-.186.384-.412.384m-1.647 2.308h-2.47c-.228 0-.412-.172-.412-.385 0-.212.184-.384.411-.384h2.47c.227 0 .413.172.413.384 0 .213-.186.385-.412.385m7.944-12.195L19.94 7.148l-1.355-1.266a.434.434 0 0 0-.583 0l-1.356 1.266-1.355-1.266a.434.434 0 0 0-.584 0l-1.355 1.266-1.356-1.266a.434.434 0 0 0-.583 0L10.06 7.148 8.703 5.882C8.441 5.639 8 5.814 8 6.154v17.692c0 .342.444.514.703.272l1.356-1.266 1.355 1.266c.162.15.422.15.583 0l1.356-1.266 1.355 1.266c.162.15.422.15.584 0l1.355-1.266 1.356 1.266c.161.15.421.15.583 0l1.355-1.266 1.356 1.266c.258.241.703.07.703-.272V6.154c0-.34-.442-.515-.703-.272"
            fill="currentColor"
          />
        </g>
      );

    case 'dashboard':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M7.083 7h15.834C24.067 7 25 7.895 25 9v12c0 1.105-.933 2-2.083 2H7.083C5.933 23 5 22.105 5 21V9c0-1.105.933-2 2.083-2zm1.319 14.723a.849.849 0 0 0 1.106-.391l1.999-4.157 1.655 1.588a.856.856 0 0 0 .736.222.83.83 0 0 0 .61-.452l1.915-3.982 1.662 2.127a.85.85 0 0 0 .8.31.825.825 0 0 0 .658-.537l2.5-7.198a.793.793 0 0 0-.527-1.012c-.437-.14-.909.087-1.054.507l-1.997 5.75-1.546-1.979a.85.85 0 0 0-.755-.315.832.832 0 0 0-.669.46l-2 4.157-1.654-1.588a.856.856 0 0 0-.736-.222.83.83 0 0 0-.61.453l-2.5 5.198a.787.787 0 0 0 .407 1.061z"
            fill="currentColor"
          />
        </g>
      );

    case 'vendor':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M22.083 19.667c-.23 0-.416.174-.416.389 0 .213.186.388.416.388h2.5c.23 0 .417-.175.417-.388 0-.215-.187-.39-.417-.39h-2.5zm-2.916-3c1.165.282 2.078 1.134 2.381 2.221h3.035c.23 0 .417-.174.417-.388v-3.89c0-1.929-1.683-3.5-3.75-3.5h-1.667c-.23 0-.416.175-.416.39v5.166zM5.417 8C5.187 8 5 8.174 5 8.389v10.889c0 .214.187.389.417.389h.416c0-1.716 1.495-3.111 3.334-3.111 1.838 0 3.333 1.395 3.333 3.11H15c0-1.715 1.495-3.11 3.333-3.11V8.389c0-.215-.186-.389-.416-.389h-12.5zm12.916 9.333c-1.378 0-2.5 1.047-2.5 2.334 0 1.286 1.122 2.333 2.5 2.333 1.379 0 2.5-1.047 2.5-2.333 0-1.287-1.121-2.334-2.5-2.334zm-9.166 0c-1.379 0-2.5 1.047-2.5 2.334 0 1.286 1.121 2.333 2.5 2.333 1.378 0 2.5-1.047 2.5-2.333 0-1.287-1.122-2.334-2.5-2.334z"
            fill="currentColor"
          />
        </g>
      );

    case 'market':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M23 11.136v10.637C23 22.45 22.463 23 21.8 23H8.2c-.663 0-1.2-.55-1.2-1.227V11.136c0-.677.537-1.227 1.2-1.227h13.6c.318 0 .623.13.849.36.225.23.351.542.351.867zm-13.2-4.5c-.22 0-.4-.183-.4-.409C9.4 5.55 9.937 5 10.6 5h8.8c.663 0 1.2.55 1.2 1.227 0 .226-.18.41-.4.41H9.8zm12 2.046c0 .226-.18.409-.4.409H8.6c-.22 0-.4-.183-.4-.41 0-.677.537-1.226 1.2-1.226h11.2c.663 0 1.2.549 1.2 1.227z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      );

    case 'user':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M20 12c0-1.381-.56-2.631-1.464-3.535A4.985 4.985 0 0 0 15 7c-1.381 0-2.631.56-3.536 1.465A4.984 4.984 0 0 0 10 12c0 1.381.56 2.631 1.464 3.535A4.985 4.985 0 0 0 15 17c1.381 0 2.631-.56 3.536-1.465A4.984 4.984 0 0 0 20 12zM9 22c0 1 2.25 2 6 2 3.518 0 6-1 6-2 0-2-2.354-4-6-4-3.75 0-6 2-6 4z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      );

    case 'logoutCircle':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M21.642 7.51v14.98a.51.51 0 0 1-.51.51H9.512A.511.511 0 0 1 9 22.49v-6.733h5.23l-1.627 2.182a.689.689 0 0 0 .134.958l.006.006a.688.688 0 0 0 .954-.132l2.499-3.348a.681.681 0 0 0 .134-.411v-.024a.681.681 0 0 0-.134-.41l-2.499-3.349a.688.688 0 0 0-.954-.132l-.006.006a.689.689 0 0 0-.134.958l1.628 2.182H9V7.51c0-.282.23-.51.512-.51h11.62a.51.51 0 0 1 .51.51z"
            fill="currentColor"
          />
        </g>
      );

    case 'email':
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M16 16.888L30.682 5.47A2.94 2.94 0 0 0 29.088 5H2.91c-.563 0-1.085.164-1.531.436L16 16.888z"
            fill="currentColor"
          />
          <path
            d="M31.652 6.53L16.428 18.354a.722.722 0 0 1-.864-.001L.378 6.473c-.234.409-.376.87-.378 1.371v15.642c.002 1.567 1.306 2.844 2.91 2.844h26.175c1.604 0 2.909-1.277 2.91-2.844V7.844c0-.476-.13-.92-.343-1.314"
            fill="currentColor"
          />
        </g>
      );

    case 'phone':
      return (
        <path
          d="M30.607 23.223l-3.413-3.413a3.564 3.564 0 0 0-5.043 0l-.914.914a75.797 75.797 0 0 1-9.96-9.962l.914-.914a3.57 3.57 0 0 0 0-5.042L8.778 1.393a3.564 3.564 0 0 0-5.044 0L1.862 3.265a5.19 5.19 0 0 0-.653 6.51 75.081 75.081 0 0 0 21.016 21.017 5.168 5.168 0 0 0 6.51-.654l1.872-1.873a3.562 3.562 0 0 0 0-5.042"
          fill="currentColor"
          fillRule="evenodd"
        />
      );

    case 'information':
      return (
        <path
          d="M14.4 24h3.2v-9.6h-3.2V24zM16 0C7.168 0 0 7.168 0 16s7.168 16 16 16 16-7.168 16-16S24.832 0 16 0zm0 28.8C8.944 28.8 3.2 23.056 3.2 16 3.2 8.944 8.944 3.2 16 3.2c7.056 0 12.8 5.744 12.8 12.8 0 7.056-5.744 12.8-12.8 12.8zm-1.6-17.6h3.2V8h-3.2v3.2z"
          fill="currentColor"
          fillRule="nonzero"
        />
      );

    case 'edit':
      return (
        <path
          d="M.023 31.299l1.469-4.897A5.815 5.815 0 0 1 2.95 23.96L21.617 5.29a.727.727 0 0 1 1.028 0l4.06 4.062a.727.727 0 0 1 0 1.028L8.038 29.049a5.82 5.82 0 0 1-2.443 1.46L.702 31.977a.546.546 0 0 1-.679-.678zM29.365.637l1.999 1.998c.848.849.848 2.224 0 3.073l-2.436 2.435a.724.724 0 0 1-1.025 0l-4.047-4.047a.725.725 0 0 1 0-1.024L26.292.636a2.172 2.172 0 0 1 3.073 0z"
          fill="currentColor"
          fillRule="nonzero"
        />
      );

    case 'delete':
      return (
        <path
          d="M26.82 9.332H4.323C3.593 9.332 3 8.735 3 8V6.665c0-.736.592-1.333 1.323-1.333h3.97c0-1.954.407-3.095 1.313-4.008C10.512.41 11.642 0 13.58 0h3.982c1.94 0 3.069.41 3.974 1.324.906.913 1.314 2.054 1.314 4.008h3.97c.73 0 1.323.597 1.323 1.333V8c0 .736-.593 1.333-1.323 1.333zm-7.273-6.004a2.529 2.529 0 0 0-1.988-.662h-3.976a2.529 2.529 0 0 0-1.987.662 2.578 2.578 0 0 0-.656 2.004h9.263a2.578 2.578 0 0 0-.656-2.004zm5.943 7.338a.66.66 0 0 1 .483.21.67.67 0 0 1 .18.498l-1.168 18.75A1.996 1.996 0 0 1 22.997 32H8.144a1.996 1.996 0 0 1-1.988-1.875L4.988 11.374a.67.67 0 0 1 .18-.499.66.66 0 0 1 .483-.21H25.49z"
          fill="currentColor"
          fillRule="nonzero"
        />
      );

    case 'download':
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M25.332 14.667l-4 .001V.667A.668.668 0 0 0 20.665 0h-9.333c-.367 0-.667.3-.667.667v14h-4c-.586 0-.89.717-.472 1.138l9.334 9.334c.25.249.693.249.944 0l9.333-9.334a.668.668 0 0 0-.472-1.138"
            fill="currentColor"
          />
          <path
            d="M30.667 23.325c-.738 0-1.334.596-1.334 1.334v4.666H2.667V24.66a1.333 1.333 0 1 0-2.667 0v6c0 .736.596 1.333 1.333 1.333h29.334c.736 0 1.333-.597 1.333-1.333v-6c0-.738-.597-1.334-1.333-1.334"
            fill="currentColor"
          />
        </g>
      );

    case 'send':
      return (
        <path
          d="M.015 29.43l31.987-13.715L.015 2 0 12.667l22.858 3.048L0 18.763z"
          fill="currentColor"
          fillRule="nonzero"
        />
      );

    case 'open':
      return (
        <path
          d="M28.444 28.444H3.556V3.556H16V0H3.556A3.555 3.555 0 0 0 0 3.556v24.888A3.555 3.555 0 0 0 3.556 32h24.888A3.555 3.555 0 0 0 32 28.444V16h-3.556v12.444zM19.556 0v3.556h6.373L8.453 21.03l2.516 2.516L28.444 6.07v6.373H32V0H19.556z"
          fill="currentColor"
          fillRule="nonzero"
        />
      );

    case 'arrowDown':
      return (
        <path
          stroke="currentColor"
          strokeWidth="4.655"
          d="M30.364 9.636L15.938 22.364 2.364 9.636"
          fill="none"
          fillRule="evenodd"
        />
      );

    case 'close':
      return (
        <g stroke="currentColor" strokeWidth="5" fill="none" fillRule="evenodd" strokeLinecap="square">
          <path d="M28 4L4 28M28 28L4 4" />
        </g>
      );

    case 'back':
      return (
        <path
          d="M7.6 14L18.1 3.5a1 1 0 0 0-.007-1.407L16.707.707a1.003 1.003 0 0 0-1.415.001L0 16l15.292 15.292c.391.391 1.03.387 1.415.001l1.386-1.386c.39-.39.384-1.03.006-1.408L7.6 18h23.398A1 1 0 0 0 32 16.99v-1.98c0-.558-.444-1.01-1.002-1.01H7.6z"
          fill="currentColor"
          fillRule="evenodd"
        />
      );

    case 'forward':
      return (
        <path
          d="M7.6 14L18.1 3.5a1 1 0 0 0-.007-1.407L16.707.707a1.003 1.003 0 0 0-1.415.001L0 16l15.292 15.292c.391.391 1.03.387 1.415.001l1.386-1.386c.39-.39.384-1.03.006-1.408L7.6 18h23.398A1 1 0 0 0 32 16.99v-1.98c0-.558-.444-1.01-1.002-1.01H7.6z"
          fill="currentColor"
          fillRule="evenodd"
          transform="scale(-1,1) translate(-32,0)"
        />
      );

    case 'copy':
      return (
        <path
          d="M12.8421053,3 L5.26315789,3 C4.56842105,3 4,3.57272727 4,4.27272727 L4,13.1818182 L5.26315789,13.1818182 L5.26315789,4.27272727 L12.8421053,4.27272727 L12.8421053,3 Z M12.2105263,5.54545455 L16,9.36363636 L16,15.7272727 C16,16.4272727 15.4315789,17 14.7368421,17 L7.78315789,17 C7.08842105,17 6.52631579,16.4272727 6.52631579,15.7272727 L6.53263158,6.81818182 C6.53263158,6.11818182 7.09473684,5.54545455 7.78947368,5.54545455 L12.2105263,5.54545455 Z M11.5789474,10 L15.0526316,10 L11.5789474,6.5 L11.5789474,10 Z"
          id="Shape"
          fill="currentColor"
          fillRule="evenodd"
        />
      );

    case 'search':
      return (
        <g
          transform="translate(1 1)"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <path d="M29 29l-7.733-7.733" />
          <circle cx="12.429" cy="12.429" r="12.429" />
        </g>
      );

    case 'check':
      return (
        <path stroke="currentColor" strokeWidth="4.667" d="M2 14.476L12.51 25 30 6" fill="none" fillRule="evenodd" />
      );

    case 'warning':
      return (
        <path strokeWidth="1.667" fill="currentColor" fillRule="evenodd" d="M23.32 17.191L15.438 2.184C14.728.833 13.416 0 11.996 0c-1.42 0-2.733.833-3.443 2.184L.533 17.448a4.744 4.744 0 000 4.368C1.243 23.167 2.555 24 3.975 24h16.05C22.22 24 24 22.044 24 19.632c0-.904-.251-1.746-.68-2.44zm-9.622 1.46c0 1.033-.724 1.823-1.698 1.823s-1.698-.79-1.698-1.822v-.043c0-1.028.724-1.822 1.698-1.822s1.698.79 1.698 1.822v.043zm.039-12.285l-.84 8.06c-.057.581-.408.943-.897.943-.49 0-.84-.367-.896-.942l-.84-8.065c-.057-.624.25-1.095.779-1.095h1.91c.528.005.84.476.784 1.1z"></path>
      );
    
    case 'success':
      return (
        <path fill="currentColor" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path>
      );

    case 'recycle':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 31.944 30.527">
          <path id="Path_10" data-name="Path 10" d="M.327,22.319H2.938L4.33,17.011l.014-.055L5.786,11.43l.018-.05L7.246,5.854,7.259,5.8,8.392,1.474A1.951,1.951,0,0,1,10.293,0h5a1.951,1.951,0,0,1,1.9,1.474l1.133,4.321.014.059,1.442,5.526.018.05,1.442,5.526.014.055,1.387,5.308h2.615a.326.326,0,0,1,.327.327V27.2a.326.326,0,0,1-.327.327H.327A.326.326,0,0,1,0,27.2V22.647a.326.326,0,0,1,.327-.327M7.992,5.581h9.6L16.561,1.637A1.328,1.328,0,0,0,16.1.928,1.309,1.309,0,0,0,15.292.66h-5a1.29,1.29,0,0,0-1.269.978Zm9.77.655H7.823L6.536,11.162H19.049Zm1.46,5.581H6.363L5.076,16.738H20.509Zm1.46,5.576H4.9L3.616,22.319H21.969Zm4.248,5.581H.655v3.9H24.93Z" transform="translate(0 0.001)" fill="#30383b" fillRule="evenodd"/>
        </svg>
      );
    case 'routes':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 31.944 30.527">
          <path d="M.606,1.81V6.156a1.206,1.206,0,0,0,1.2,1.208H19.963a1.213,1.213,0,0,0,1.2-1.208V1.81a1.186,1.186,0,0,0-.355-.853A1.2,1.2,0,0,0,19.963.6H1.81A1.206,1.206,0,0,0,.606,1.81m11.359,14.2a1.814,1.814,0,0,1,.8.468,1.786,1.786,0,0,1,.531,1.279v7.961a1.812,1.812,0,0,1-1.805,1.81H10.28a1.812,1.812,0,0,1-1.81-1.81V17.757A1.818,1.818,0,0,1,9,16.478l0,0a1.846,1.846,0,0,1,.8-.464V11.217a.3.3,0,0,1,.3-.3H22.721v-5.8h-.953V6.156a1.812,1.812,0,0,1-1.805,1.81H1.81A1.812,1.812,0,0,1,0,6.156V1.81A1.818,1.818,0,0,1,.531.531h0A1.783,1.783,0,0,1,1.81,0H19.963a1.783,1.783,0,0,1,1.275.531,1.818,1.818,0,0,1,.531,1.279V2.85h2.8a.3.3,0,0,1,.3.3v9.62a.3.3,0,0,1-.3.3H11.965Zm-1.684.539a1.213,1.213,0,0,0-1.208,1.208v7.961a1.213,1.213,0,0,0,1.208,1.208h1.212a1.184,1.184,0,0,0,.848-.355h0a1.2,1.2,0,0,0,.351-.853V17.757a1.186,1.186,0,0,0-.355-.853,1.2,1.2,0,0,0-.848-.355Zm.13-.6V11.522H23.022a.3.3,0,0,0,.3-.305v-6.4a.3.3,0,0,0-.3-.3H21.769V3.452h2.5V12.47H11.659a.3.3,0,0,0-.3.3v3.176Z" transform="translate(0 0.002)" fill="#30383b" fillRule="evenodd"/>
        </svg>
        );
    case 'star':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 31.944 30.527">
          <path d="M1.3 10.563L10.46 9.7A.646.646 0 0 1 11 9.314L14.658.869a1.386 1.386 0 0 1 .527-.635l.033-.016a1.442 1.442 0 0 1 1.548.016A1.492 1.492 0 0 1 17.3.869l3.644 8.445a.652.652 0 0 0 .212.277.677.677 0 0 0 .326.119l9.162.864.016.005a1.415 1.415 0 0 1 .749.3 1.4 1.4 0 0 1 .462.679 1.367 1.367 0 0 1 .022.82 1.416 1.416 0 0 1-.44.7l-6.9 6.077a.636.636 0 0 0-.2.288.747.747 0 0 0-.016.348l2.009 8.977a1.394 1.394 0 0 1-.054.82 1.416 1.416 0 0 1-.5.657 1.447 1.447 0 0 1-.782.272 1.371 1.371 0 0 1-.8-.2L16.3 25.634a.619.619 0 0 0-.668 0L7.712 30.315a1.459 1.459 0 0 1-.8.2 1.469 1.469 0 0 1-1.282-.929 1.511 1.511 0 0 1-.054-.825L7.6 19.79v-.011a.7.7 0 0 0-.016-.337.6.6 0 0 0-.2-.288l-6.9-6.083a1.44 1.44 0 0 1-.44-.7 1.451 1.451 0 0 1 .489-1.5 1.468 1.468 0 0 1 .766-.3m9.233-.081-9.162.858a.62.62 0 0 0-.348.136.651.651 0 0 0-.212.315.633.633 0 0 0-.011.375.585.585 0 0 0 .2.315l6.9 6.088a1.386 1.386 0 0 1 .418.63 1.434 1.434 0 0 1 .033.744v.016L6.343 28.936a.625.625 0 0 0 .022.375.671.671 0 0 0 .228.3.7.7 0 0 0 .353.125.657.657 0 0 0 .369-.092l7.918-4.681a1.441 1.441 0 0 1 .733-.206 1.477 1.477 0 0 1 .728.206l7.913 4.687a.629.629 0 0 0 .722-.033.641.641 0 0 0 .234-.293.69.69 0 0 0 .022-.38l-2.009-8.977a1.5 1.5 0 0 1 .033-.76 1.443 1.443 0 0 1 .424-.63l6.9-6.077a.678.678 0 0 0 .206-.315.7.7 0 0 0-.011-.38.671.671 0 0 0-.217-.31.649.649 0 0 0-.337-.141h-.011l-9.156-.864a1.452 1.452 0 0 1-.711-.266 1.392 1.392 0 0 1-.472-.6l-3.65-8.445a.658.658 0 0 0-.94-.3l-.022.011a.674.674 0 0 0-.239.293L11.72 9.624a1.424 1.424 0 0 1-1.184.858" fill="#30383b" />
        </svg>
      );
    case 'value':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 31.944 30.527">
          <path d="M2.312,15.508a.39.39,0,0,1,0-.779H9.78a1.571,1.571,0,0,0,1.564-1.564V10.491a1.578,1.578,0,0,0-.46-1.1,1.554,1.554,0,0,0-1.1-.455H4.265a2.345,2.345,0,0,1-1.656-.687L2.6,8.239a2.337,2.337,0,0,1-.682-1.651V3.915A2.347,2.347,0,0,1,4.265,1.577h6.8a.39.39,0,1,1,0,.779h-6.8a1.554,1.554,0,0,0-1.1.455,1.578,1.578,0,0,0-.46,1.1V6.589a1.554,1.554,0,0,0,.455,1.1h.005a1.558,1.558,0,0,0,1.1.46H9.78a2.35,2.35,0,0,1,1.656.682V8.84a2.332,2.332,0,0,1,.687,1.651v2.674A2.347,2.347,0,0,1,9.78,15.508Z" transform="translate(8.48 6.959)" fill="#30383b" fillRule="evenodd"/>
          <path d="M2.792,1.4a.39.39,0,0,1,.779,0v19.24a.39.39,0,1,1-.779,0Z" transform="translate(12.318 4.475)" fill="#30383b" fillRule="evenodd"/>
          <path d="M15.5.785A14.718,14.718,0,1,0,25.907,5.093,14.671,14.671,0,0,0,15.5.785M4.541,4.541A15.5,15.5,0,1,1,0,15.5,15.453,15.453,0,0,1,4.541,4.541" transform="translate(0 0.002)" fill="#30383b" fillRule="evenodd"/>
          <path d="M12.567,23.683a11.119,11.119,0,1,0-4.259-21.4A11.148,11.148,0,0,0,2.284,8.308,11.12,11.12,0,0,0,12.567,23.683m4.546-.124a11.906,11.906,0,1,1,3.864-2.582,11.889,11.889,0,0,1-3.864,2.582" transform="translate(2.938 2.94)" fill="#30383b" fillRule="evenodd"/>
        </svg>
      );
    default:
      return null;
  }
};

export const renderViewBox = (viewBox: string): string => {
  switch (viewBox) {
    case 'recycle':
    case 'routes':
    case 'star':
    case 'value':
      return '0 0 24 24';
    default:
      return '0 0 32 32';
  }
};

const Icon: React.FC<ComponentProps> = ({ className, icon }) => (
  <svg viewBox={renderViewBox(icon)} preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" className={className}>
    {renderIcon(icon)}
  </svg>
);

interface OwnProps {
  icon: string;
  small?: boolean;
}
type ComponentProps = OwnProps & SVGProps<SVGElement>;

export default Icon;
