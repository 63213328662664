import update from 'immutability-helper';

import { HolidaysAction } from 'contracts/types/action';
import { HolidayCalendarDataView } from 'contracts/types/response';
import { HolidaysState, ReduceFunctionMap } from 'contracts/types/state';
import { getReducerBuilder } from 'core/utils/reducer/reducerBuilder';

// Actions Keys
const ROOT_KEY = 'holidays';
enum ActionKey {
  LOAD_HOLIDAYS = 'holidays/LOAD_HOLIDAYS',
  RESET = 'holidays/RESET'
}

// Initial state
const getInitialState: () => HolidaysState = () => {
  return {
    holidays: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOAD_HOLIDAYS,
  ActionKey.RESET,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  HolidaysState,
  HolidaysAction
> = {
  [ActionKey.LOAD_HOLIDAYS]: (state, action) => {
    const { holidays } = action;
    return update(state, { $merge: { holidays } });
  },
  [ActionKey.RESET]: state => {
    return update(state, { $set: { holidays: [] } });
  }
};

export const reducer = getReducerBuilder<HolidaysState, HolidaysAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions creators
const actionMap = {
  LOAD_HOLIDAYS: (holidays?: HolidayCalendarDataView[]): HolidaysAction => ({
    type: ActionKey.LOAD_HOLIDAYS,
    holidays,
  }),
  RESET: (): HolidaysAction => ({
    type: ActionKey.RESET,
  }),
};

export const hollidaysDuck = {
  actionKeys: ActionKey,
  actions: { 
    getCCSignature: actionMap.LOAD_HOLIDAYS, 
    reset: actionMap.RESET, 
  },
};
