/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import FullStory from 'react-fullstory';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';

import { Environments } from 'contracts/enums';
import NotificationList from 'core/components/NotificationList';
import { QUERY_STALE_TIME } from 'core/constants/queryStaleTime';
import { environment } from 'core/services/environment';
import registerAuthInterceptor from 'core/services/registerAuthInterceptor';
import GlobalStyle from 'core/styles/base';
import theme from 'core/styles/theme';
import MainRouter from 'routing/MainRouter';

import store from './store';

registerAuthInterceptor();

declare global {
  interface Window {
    Z: {
      renderWithErrorHandler: (...data: any) => any;
      sendErrorMessageToHpm: (...data: any) => any;
      submit: (...data: any) => any;
    };
    renderCheckoutmicro?: (containerId: string) => void;
    unmountCheckoutmicro?: (containerId: string) => void;
  }
}

export const IsMicroSite = Boolean(document.getElementById('Checkoutmicro-container'));
const baseName = IsMicroSite ? '/open-top-temp' : '';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: QUERY_STALE_TIME,
      retry: false
    },
  },
});

const ORG_ID = '186P4G'; // Full Story org ID

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Provider store={store}>
          <BrowserRouter basename={baseName}>
            <QueryClientProvider client={queryClient}>
                <MainRouter />
                <NotificationList />
            </QueryClientProvider>
          </BrowserRouter>
        </Provider>
        <Normalize />
        <GlobalStyle />
        {environment !== Environments.local &&
          <FullStory org={ORG_ID} />
        }
      </React.Fragment>
    </ThemeProvider>
  );
};

export default App;
