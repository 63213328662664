import axios, { AxiosError } from 'axios';
import { MutationFunction, QueryFunction, useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { QueryUrlKey } from 'contracts/enums';
import { ServiceError } from 'contracts/types/response';
import API_KEY from 'core/constants/apiKey';
import { createServiceErrorNotificationMessage } from 'core/ducks/notifier';
import { logError as logErrorFunc } from 'core/utils/helpers/logError';

import { apiBaseURLs } from './environment';

// test if any api response is not camelCase, unlikely
// const getAxiosTransformers = (
//   ...concatWithItems: AxiosResponseTransformer[]
// ): AxiosResponseTransformer[] => {
//   const transformResponse = Array.isArray(axios.defaults.transformResponse)
//     ? axios.defaults.transformResponse
//     : axios.defaults.transformResponse
//       ? [axios.defaults.transformResponse]
//       : [];
//   return transformResponse.concat(concatWithItems);
// };

// const camelizeResponse: AxiosResponseTransformer = (data: Record<string, unknown>) => {
//   if (isPlainObject(data)) {
//     return humps.camelizeKeys(data); 
//   } else return data;
// };

const http = axios.create({
  baseURL: `${apiBaseURLs.checkoutCore}/`,
  responseType: 'json',
  headers: {
    'ocp-apim-subscription-key': API_KEY,
  },
  transformResponse: axios.defaults.transformResponse,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useReactQuery = <TData>(
  queryKey: QueryUrlKey,
  queryFn: QueryFunction<TData, QueryUrlKey>,
  customOnSuccess?: (data: TData) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void,
  showErrorNotification = true,
  logError = true,
  queryOptions: Omit<UseQueryOptions<TData, AxiosError<ServiceError>, TData, QueryUrlKey>, 'queryKey' | 'queryFn'> | undefined = undefined) => {

  const dispatch = useDispatch();
  const useQueryResult = useQuery(queryKey, queryFn, {
    onSuccess: customOnSuccess,
    onError: (error: AxiosError<ServiceError>) => {
      if (customOnError) {
        customOnError(error);
      }
      if (showErrorNotification) {
        dispatch(createServiceErrorNotificationMessage(error));
      }
      if (logError) {
        logErrorFunc(useQueryResult.error);
      }
    },
    ...queryOptions
  });
  
  return useQueryResult;
}; 

export const useInactiveReactQuery = <TData>(
  queryKey: QueryUrlKey,
  queryFn: QueryFunction<TData, QueryUrlKey>,
  customOnSuccess?: (data: TData) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void,
  showErrorNotification = true,
  logError = true,
  queryOptions: Omit<UseQueryOptions<TData, AxiosError<ServiceError>, TData, QueryUrlKey>, 'queryKey' | 'queryFn' | 'enabled'> | undefined = undefined): UseQueryResult<TData, AxiosError<ServiceError>> => {

  return useReactQuery(queryKey, queryFn, customOnSuccess, customOnError, showErrorNotification, logError, {
    ...queryOptions,
    enabled: false
  });
}; 

export const useQueryMutation = <TData, TVariables>(
  mutationKey: QueryUrlKey,
  mutationFn: MutationFunction<TData, TVariables>,
  customOnSuccess?: (data: TData) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void,
  showErrorNotification = true,
  logError = true,
  queryOptions: Omit<UseMutationOptions<TData, AxiosError<ServiceError>, TVariables>, 'mutationKey' | 'mutationFn'> | undefined = undefined): UseMutationResult<TData, AxiosError<ServiceError>, TVariables> => {

  const dispatch = useDispatch();
  const useMutationResult = useMutation(mutationKey, mutationFn, {
    onSuccess: customOnSuccess,
    onError: (error: AxiosError<ServiceError>) => {
      if (customOnError) {
        customOnError(error);
      }
      if (showErrorNotification) {
        dispatch(createServiceErrorNotificationMessage(error));
      }
      if (logError) {
        logErrorFunc(useMutationResult.error);
      }
    },
    ...queryOptions
  });
  
  return useMutationResult;
}; 

export default http;
