import update from 'immutability-helper';

import { AgreementAction, CartAction } from 'contracts/types/action';
import { AgreementState, ReduceFunctionMap } from 'contracts/types/state';
import { getReducerBuilder } from 'core/utils/reducer/reducerBuilder';

// Actions
const ROOT_KEY = 'checkout/agreement';
enum ActionKey {
  SERVICE_AGREEMENT_CONFIRMATION = 'agreement/SERVICE_AGREEMENT_CONFIRMATION',
  LOA_CONFIRMATION = 'agreement/LOA_CONFIRMATION',
  RESET = 'agreement/RESET'
}
type ReducerKey = typeof reducerKeys[number];

const getInitialState: () => AgreementState = () => {
  return {
    isServiceAgreementConfirmed: false,
    isLoaConfirmed: false,
    serviceAgreementId: undefined,
    loaAgreementId: undefined,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.SERVICE_AGREEMENT_CONFIRMATION,
  ActionKey.LOA_CONFIRMATION,
  ActionKey.RESET,
] as const;

const reducerFunctionMap: ReduceFunctionMap<
ReducerKey,
AgreementState,
AgreementAction
> = { 
  [ActionKey.SERVICE_AGREEMENT_CONFIRMATION]: (state, action) => {
    const { serviceAgreementId } = action;
    return update(state, {
      $merge: {
        isServiceAgreementConfirmed: true,
        serviceAgreementId: serviceAgreementId,
      },
    });
  },
  [ActionKey.LOA_CONFIRMATION]: (state, action) => {
    const { loaAgreementId } = action;
    return update(state, {
      $merge: {
        isLoaConfirmed: true,
        loaAgreementId: loaAgreementId,
      },
    });
  },
  [ActionKey.RESET]: state => {
    return update(state, {
      $merge: {
        isServiceAgreementConfirmed: false,
        isLoaConfirmed: false,
        serviceAgreementId: undefined,
        loaAgreementId: undefined,
      },
    });
  }
};

export const reducer = getReducerBuilder<
AgreementState,
AgreementAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions creators
const actionMap = { 
  SERVICE_AGREEMENT_CONFIRMATION: (serviceAgreementId?: string): AgreementAction => ({
    type: ActionKey.SERVICE_AGREEMENT_CONFIRMATION,
    isServiceAgreementConfirmed: true,
    serviceAgreementId
  }),
  LOA_CONFIRMATION: (loaAgreementId?: string): AgreementAction => ({
    type: ActionKey.LOA_CONFIRMATION,
    isLoaConfirmed: true,
    loaAgreementId
  }),
  RESET: (): CartAction => ({
    type: ActionKey.RESET,
  })
};

export const agreementDuck = {
  actionKeys: ActionKey,
  actions: {
    confirmServiceAgreement: actionMap.SERVICE_AGREEMENT_CONFIRMATION,
    confirmLoaAgreement: actionMap.LOA_CONFIRMATION,
    reset: actionMap.RESET
  }
};

