import { createGlobalStyle } from 'styled-components';

import arsMaquetteBold from 'core/assets/fonts/arsMaquetteBold.woff';
import arsMaquetteLight from 'core/assets/fonts/arsMaquetteLight.woff';
import arsMaquetteMedium from 'core/assets/fonts/arsMaquetteMedium.woff';
import arsMaquetteRegular from 'core/assets/fonts/arsMaquetteRegular.woff';

import theme from './theme';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Ars Maquette";
    src: url(${arsMaquetteBold}) format(woff);
    font-weight: 600;
  }

  @font-face {
    font-family: "Ars Maquette";
    src: url(${arsMaquetteMedium}) format(woff);
    font-weight: 500;
  }

  @font-face {
    font-family: "Ars Maquette";
    src: url(${arsMaquetteRegular}) format(woff);
    font-weight: 400;
  }

  @font-face {
    font-family: "Ars Maquette";
    src: url(${arsMaquetteLight}) format(woff);
    font-weight: 300;
  }

  * {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    background: ${theme.colors.bodyBackground};
    text-rendering: optimizeLegibility;
    font-family: ${theme.fontFamilyBase};
    font-size: ${theme.fontSizeBase};
    color: ${theme.colors.brandDefault};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 10px;
    font-weight: 400;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;
