import { transparentize } from 'polished';
import { css, FlattenSimpleInterpolation } from 'styled-components';

import { rotate } from './animations';

export const loading = (size: number): FlattenSimpleInterpolation => css`
  display: inline-block;
  width: ${size}px;
  height: ${size}px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjMWExZDFhIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPjxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCIvPjwvZz48L3N2Zz4=')
    no-repeat center;
    background-size: ${size}px;
  animation: ${rotate} 0.4s linear infinite;
`;

export const loadingOverlay = (size: number, backgroundColor = '#fff', zIndex = 1000, transparency = 0.7, top = '50%',): FlattenSimpleInterpolation => css`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${zIndex};
    background: ${transparentize(transparency, backgroundColor)};
  }

  &:after {
    content: '';
    position: absolute;
    top: ${top};
    left: 50%;
    z-index: ${zIndex};
    margin: -13px 0 0 -13px;
    ${loading(size)};
  }
`;
