export enum ApiUrlKey {
  chekoutAccess = 'sale/checkout/access',
  oneTimeCheckout = 'onetime/checkout',
  agreementconfirmation = 'onetime/checkout/agreementconfirmation',
  loaConfirmation = 'onetime/checkout/loaconfirmation',
  pickUpdate = '/ondemand/pickupdate',
  holidayCalendar = 'holidaycalendar/nextyears/',
  billingIdentifier = 'savings/billingidentifier/',
  checkPrices = 'sale/price/ondemand/temp',
  paymentFormData = 'payment/checkout/formdata',
  marketConfig = 'sale/marketconfig/ondemand',
  shoppingCart = 'sale/shoppingcart/',
  addressOptions = 'sale/address/options',
  recognizedAddress = 'sale/recognized',
  openTopTempLeads = 'sale/checkout/openTopTempLeads',
}

type QueryUrlPagedKey = [ApiUrlKey, number, number];
type QueryUrlFlaggedKey = [ApiUrlKey, string];
type QueryUrlFlaggedPagedKey = [ApiUrlKey, string, number];
export type QueryUrlKey = ApiUrlKey | QueryUrlPagedKey | QueryUrlFlaggedKey | QueryUrlFlaggedPagedKey;
