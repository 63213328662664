import React, { lazy, Suspense } from 'react';

import { createRoot, Root } from 'react-dom/client';

import GoogleMapsScript from 'core/components/scripts/GoogleMapsScript';
import GoogleTagManagerScript from 'core/components/scripts/GoogleTagManagerScript';
import ZuoraScript from 'core/components/scripts/ZuoraScript';
import PageLoading from 'core/components/styled/PageLoading';

import { IsMicroSite } from './App';
import * as serviceWorker from './serviceWorker';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const App = lazy(() => import('./App'));
let root: Root;

if (!IsMicroSite) {
  const container = document.getElementById('root') as HTMLElement;
  root = createRoot(container);
  root.render(
    <Suspense fallback={<PageLoading />}>
      <ZuoraScript />
      <GoogleTagManagerScript />
      <GoogleMapsScript>
        <App />
      </GoogleMapsScript>
    </Suspense>,
  );
} else {
  // mount micro frontend function
  window.renderCheckoutmicro = (containerId: string) => {
    const container = document.getElementById(containerId) as HTMLElement;
    if (container) {
      root = createRoot(container);
      root.render(
        <Suspense fallback={<PageLoading />}>
          <App />
        </Suspense>,
      );
    }
  };

  // unmount micro frontend function
  window.unmountCheckoutmicro = () => {
    if (root) root.unmount();
  };
}
