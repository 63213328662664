import update from 'immutability-helper';

import { CartAction } from 'contracts/types/action';
import { CheckoutInformation, CustomerCheckoutInformation } from 'contracts/types/form';
import { AddressDataView } from 'contracts/types/request';
import { PaymentMethodDetailsDataView, PriceRetailItem } from 'contracts/types/response';
import { CartState, ReduceFunctionMap } from 'contracts/types/state';
import { getSessionCart, setSessionCart } from 'core/utils/helpers/session';
import { getReducerBuilder } from 'core/utils/reducer/reducerBuilder';

// Actions
const ROOT_KEY = 'checkout/cart';
enum ActionKey {
  ADD_LOCATION = 'checkout/cart/ADD_LOCATION',
  ADD_CONTAINER = 'checkout/cart/ADD_CONTAINER',
  ADD_CHECKOUT_INFORMATION = 'checkout/cart/ADD_CHECKOUT_INFORMATION',
  ADD_PAYMENT_DETAILS = 'checkout/cart/ADD_PAYMENT_DETAILS',
  ADD_CONTACT_INFORMATION = 'checkout/cart/ADD_CONTACT_INFORMATION',
  REMOVE_CONTAINER = 'checkout/cart/REMOVE_CONTAINER',
  REMOVE_LOCATION = 'checkout/cart/REMOVE_LOCATION',

  RESET = 'checkout/cart/RESET',
 }

// Initial state
const getInitialState: () => CartState = () => {
  return getSessionCart();
};

// Reducer.
const reducerKeys = [
  ActionKey.ADD_LOCATION,
  ActionKey.ADD_CONTAINER,
  ActionKey.ADD_CHECKOUT_INFORMATION,
  ActionKey.ADD_PAYMENT_DETAILS,
  ActionKey.ADD_CONTACT_INFORMATION,
  ActionKey.REMOVE_CONTAINER,
  ActionKey.REMOVE_LOCATION,
  ActionKey.RESET,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  CartState,
  CartAction
> = { 
  [ActionKey.ADD_LOCATION]: (state, action) => {
    const { address, recognizedAddress } = action;
    const newState = update(state, {
      $merge: {
        address,
        recognizedAddress
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.ADD_CONTAINER]: (state, action) => {
    const { container } = action;
    const newState = update(state, {
      $merge: {
        container,
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.ADD_CHECKOUT_INFORMATION]: (state, action) => {
    const { checkout } = action;
    const newState = update(state, {
      $merge: {
        checkout,
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.ADD_PAYMENT_DETAILS]: (state, action) => {
    const { payment } = action;
    const newState = update(state, {
      $merge: {
        payment,
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.ADD_CONTACT_INFORMATION]: (state, action) => {
    const { profileInfo } = action.checkout as CheckoutInformation ;
    const newState = update(state, {
      $merge: {
        checkout: {
          profileInfo
        } as CheckoutInformation,
      },
    });
    if (setSessionCart(newState)) {
      return newState;
    }
    return state;
  },
  [ActionKey.REMOVE_CONTAINER]: state => {
    return update(state, {
      $merge: {
        container: undefined,
      },
    });
  },
  [ActionKey.REMOVE_LOCATION]: state => {
    return update(state, {
      $merge: {
        address: undefined,
      },
    });
  },
  [ActionKey.RESET]: state => {
    return update(state, {
      $merge: {
        address: undefined,
        container: undefined,
        checkout: undefined,
        payment: undefined
      },
    });
  },
};

export const reducer = getReducerBuilder<
CartState,
CartAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions creators
const actionMap = { 
  ADD_LOCATION: (address: AddressDataView, recognizedAddress?: boolean): CartAction => ({
    type: ActionKey.ADD_LOCATION,
    address,
    recognizedAddress
  }),
  ADD_CONTAINER: (selectedEquipment: PriceRetailItem): CartAction => ({
    type: ActionKey.ADD_CONTAINER,
    container: selectedEquipment
  }),
  ADD_CHECKOUT_INFORMATION: (checkoutInformation: CheckoutInformation): CartAction => ({
    type: ActionKey.ADD_CHECKOUT_INFORMATION,
    checkout: checkoutInformation
  }),
  ADD_PAYMENT_DETAILS: (paymentDetails: PaymentMethodDetailsDataView): CartAction => ({
    type: ActionKey.ADD_PAYMENT_DETAILS,
    payment: paymentDetails
  }),
  ADD_CONTACT_INFORMATION: (profileInfo: CustomerCheckoutInformation): CartAction => ({
    type: ActionKey.ADD_CONTACT_INFORMATION,
    checkout: { profileInfo } as CheckoutInformation
  }),
  REMOVE_CONTAINER: (): CartAction => ({
    type: ActionKey.REMOVE_CONTAINER,
    container: undefined
  }),
  REMOVE_LOCATION: (): CartAction => ({
    type: ActionKey.REMOVE_LOCATION,
    address: undefined
  }),
  RESET: (): CartAction => ({
    type: ActionKey.RESET,
  })
};

export const cartDuck = {
  thunks: {

  },
  actionKeys: ActionKey,
  actions: {
    addLocation: actionMap.ADD_LOCATION,
    addCart: actionMap.ADD_CONTAINER,
    addCheckoutInfo: actionMap.ADD_CHECKOUT_INFORMATION,
    addPaymentInfo: actionMap.ADD_PAYMENT_DETAILS,
    addContactInformation: actionMap.ADD_CONTACT_INFORMATION,
    removeContainer: actionMap.REMOVE_CONTAINER,
    removeLocation: actionMap.REMOVE_LOCATION,
    reset: actionMap.RESET
  }
};
