import update from 'immutability-helper';

import { BillingAction } from 'contracts/types/action';
import { BillingState, ReduceFunctionMap } from 'contracts/types/state';
import { ZuoraHostePageResponseDataView } from 'contracts/types/zuora';
import { getReducerBuilder } from 'core/utils/reducer/reducerBuilder';
// Actions Keys
const ROOT_KEY = 'billing';
enum ActionKey {
  ALLOW_CHECK_PAYMENT = 'billing/ALLOW_CHECK_PAYMENT',
  UPDATE_AUTOPAY_SETTINGS = 'billing/UPDATE_AUTOPAY_SETTINGS',
  GET_ACH_FORM_DATA = 'billing/GET_ACH_FORM_DATA',
  GET_CC_FORM_DATA = 'billing/GET_CC_FORM_DATA',
  RESET = 'billing/RESET',
}

// Initial state
const getInitialState: () => BillingState = () => {
  return {
    allowCheckPayment: false,
    zuoraACHFormData: undefined,
    zuoraCCFormData: undefined,
    isNewCreditCardAutopayEditable: false,
    newCreditCardAutopayDefaultValue: true,
  };
};

// Reducer
const reducerKeys = [
  ActionKey.ALLOW_CHECK_PAYMENT,
  ActionKey.UPDATE_AUTOPAY_SETTINGS,
  ActionKey.GET_ACH_FORM_DATA,
  ActionKey.GET_CC_FORM_DATA,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  BillingState,
  BillingAction
> = {
  [ActionKey.ALLOW_CHECK_PAYMENT]: (state, action) => {
    const { allowCheckPayment } = action;
    return update(state, { $merge: { allowCheckPayment } });
  },
  [ActionKey.UPDATE_AUTOPAY_SETTINGS]: (state, action) => {
    const { isNewCreditCardAutopayEditable, newCreditCardAutopayDefaultValue } = action;
    return update(state, { $merge: { isNewCreditCardAutopayEditable, newCreditCardAutopayDefaultValue } });
  },
  [ActionKey.GET_ACH_FORM_DATA]: (state, action) => {
    const { zuoraACHFormData } = action;
    return update(state, { $merge: { zuoraACHFormData } });
  },
  [ActionKey.GET_CC_FORM_DATA]: (state, action) => {
    const { zuoraCCFormData } = action;
    return update(state, { $merge: { zuoraCCFormData } });
  },
};

export const reducer = getReducerBuilder<BillingState, BillingAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(ActionKey.RESET)
  .buildReducer();

// Actions creators
const actionMap = {
  GET_ACH_SIGNATURE: (zuoraACHFormData?: ZuoraHostePageResponseDataView): BillingAction => ({
    type: ActionKey.GET_ACH_FORM_DATA,
    zuoraACHFormData,
  }),
  GET_CC_SIGNATURE: (zuoraCCFormData?: ZuoraHostePageResponseDataView): BillingAction => ({
    type: ActionKey.GET_CC_FORM_DATA,
    zuoraCCFormData,
  }),
  ALLOW_CHECK_PAYMENT: (allowCheckPayment?: boolean): BillingAction => ({
    type: ActionKey.ALLOW_CHECK_PAYMENT,
    allowCheckPayment,
  }),
  UPDATE_AUTOPAY_SETTINGS: (isNewCreditCardAutopayEditable?: boolean, newCreditCardAutopayDefaultValue?: boolean ): 
  BillingAction => ({
    type: ActionKey.UPDATE_AUTOPAY_SETTINGS,
    isNewCreditCardAutopayEditable,
    newCreditCardAutopayDefaultValue
  }),
  RESET: (): BillingAction => ({
    type: ActionKey.RESET,
  }),
};

export const billingDuck = {
  actionKeys: ActionKey,
  actions: { 
    reset: actionMap.RESET, 
    getCCSignature: actionMap.GET_CC_SIGNATURE, 
    getACHSignature: actionMap.GET_ACH_SIGNATURE 
  },
};
