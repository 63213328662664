import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import NotificationType from 'contracts/enums/NotificationType';
import { ApplicationState } from 'contracts/types/state';
import { deleteNotificationMessage } from 'core/ducks/notifier';

import {
  NotificationList as NotificationListContainer,
  Notification as NotificationContainer,
  NotificationClose,
  NotificationCloseIcon,
  NotificationWarningIcon,
  NotificationSuccessIcon,
  NotificationIconContainer,
} from './styled/NotificationList';
import TextComponent from './styled/TextComponent';

const Notification: React.FC<NotificationProps> = 
({ id, messages, notificationType, onNotificationClose, duration }) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (duration && !timer) {
      setTimer(setTimeout(() => {
        onNotificationClose(id);
      }, duration || 6000));
    }
  }, [duration, id, onNotificationClose, timer]);
  
  const onClose = (): void => {
    if (timer) {
      clearTimeout(timer);
    }
    onNotificationClose(id);
  };

  const renderNotificationIcon = (type: string): JSX.Element => {
    switch (type) {
      case NotificationType.warning:
        return (<NotificationWarningIcon />);
      case NotificationType.success:
        return (<NotificationSuccessIcon />);
      default:
        return (<></>);
    }
  };

  return (
    <NotificationContainer>
      <NotificationClose onClick={() => onClose()}>
        <NotificationCloseIcon />
      </NotificationClose>
      <NotificationIconContainer>
        {renderNotificationIcon(notificationType)}
      </NotificationIconContainer>
      {messages.map((text, index) => (
        <TextComponent margin='no no no large' key={index} block>
          {text}
        </TextComponent>
      ))}
    </NotificationContainer>);
};

interface NotificationProps {
  id: string,
  messages: string[],
  notificationType: string;
  onNotificationClose: (id: string) => void;
  duration?: number;
}

const NotificationList: React.FC = () => {
  const dispatch = useDispatch();

  const notifications = useSelector((state: ApplicationState) => state.core.notifier.notifications);

  const onNotificationClose = (id: string): void => {
    if (id) {
      dispatch(deleteNotificationMessage(id));
    }
  };

  return (
    <NotificationListContainer>
      <TransitionGroup className='notifier'>
        {notifications.map(notification =>
          <CSSTransition
            key={notification.id}
            classNames='notifier-'
            timeout={{ enter: 500, exit: 300 }}
          >
            <Notification
              id={notification.id}
              key={notification.id}
              messages={notification.messages}
              onNotificationClose={() => onNotificationClose(notification.id)}
              notificationType={notification.type}
              duration={notification.duration}
            />
          </CSSTransition>)}
      </TransitionGroup>
    </NotificationListContainer>
  );
};

export default NotificationList;
