import styled, { css } from 'styled-components';

import { mapper } from 'utils/styles';
import colorMapper, { ColorProps } from 'utils/styles/colorMapper';

interface TextComponentProps extends ColorProps {
  margin?: string;
  size?: string;
  block?: boolean;
  weight?: string;
  align?: string;
  singleLine?: boolean;
  darkGrey?: boolean;
}
const TextComponent = styled.span<TextComponentProps>`
  margin: ${props => mapper({ no: 0, small: '10px', medium: '20px', large: '30px' }, props.margin, 'no')};
  font-size: ${props => mapper({ small: '12px', medium: '14px', large: '16px' }, props.size)};

  ${props =>
    props.block &&
    css`
      display: block;
    `};

  ${props =>
    props.weight &&
    css`
      font-weight: ${mapper({ light: 300, normal: 400, medium: 500 }, props.weight)};
    `};

  ${props =>
    props.align &&
    css`
      text-align: ${mapper({ left: 'left', center: 'center', right: 'right' }, 'left'), props.align};
    `};

  ${props =>
    props.singleLine &&
    css`
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};
`;

export default TextComponent;
