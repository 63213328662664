import React, { lazy, useEffect } from 'react';

import cuid from 'cuid';
import isEmpty from 'lodash-es/isEmpty';
import queryString from 'query-string';
import { FullStoryAPI } from 'react-fullstory';
import { Route, Routes, useNavigate } from 'react-router';

import { IsMicroSite } from 'App';
import { Environments } from 'contracts/enums';
import { SavingsUserDataType } from 'contracts/types/form';
import { Page, PageContent } from 'core/components/styled/PageContent';
import { RubiconLogoInline } from 'core/components/styled/RubiconLogo';
import { environment } from 'core/services/environment';
import { logEvent } from 'core/services/logging';
import { setSavingsUserData } from 'core/utils/helpers/session';

import LazyRoute from './LazyRoute';
import { CheckoutRoutes } from './Routes';

const CheckoutPage = lazy(() => import('pages/checkout/components/CheckoutPage'));
const ServiceLocationPage = lazy(() => import('pages/serviceLocation/components/ServiceLocationPage'));
const SummaryPage = lazy(() => import('pages/summary/components/SummaryPage'));
const ConfirmedOrderPage = lazy(() => import('pages/orderConfirmation/components/ConfirmedOrderPage'));

const MainRouter: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const values = queryString.parse(location.search);
    if (!isEmpty(values) && !isEmpty(values.address)) {
      const { firstName, lastName, phone, email, address } = values;
      setSavingsUserData(
        {
          email,
          phone,
          firstName,
          lastName,
          address
        } as SavingsUserDataType 
      );
    } else {
      navigate(CheckoutRoutes.serviceLocation);
    }

    // full story
    if (environment !== Environments.local) {
      try {
        let sessionId = sessionStorage.getItem('sessionId');
        if (!sessionId) {
          sessionId = cuid();
          sessionStorage.setItem('sessionId', sessionId);
        }
        FullStoryAPI('identify', false, {
          uniqueId: sessionId as string,
        });
      } catch (err) {
        logEvent('ROUTING_EVENT_ERROR', { err });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideLogo = location.pathname.includes(CheckoutRoutes.orderConfirmation);
  const fullWidth = location.pathname === CheckoutRoutes.serviceLocation || 
    location.pathname === '/open-top-temp';

  return (
    <>
      <Page>
        <PageContent fullWidth={fullWidth}>
          {IsMicroSite || hideLogo ? null : <RubiconLogoInline />}
          <Routes>
            <Route path={CheckoutRoutes.serviceLocation} element={
              <LazyRoute><ServiceLocationPage /></LazyRoute>
            }/>
            <Route path={CheckoutRoutes.checkout} element={
              <LazyRoute><CheckoutPage /></LazyRoute>
            }/>
            <Route path={CheckoutRoutes.summary} element={
              <LazyRoute><SummaryPage /></LazyRoute>
            }/>
            <Route path={CheckoutRoutes.orderConfirmation} element={
              <LazyRoute><ConfirmedOrderPage /></LazyRoute>
            } />
            {/* Default Route */}
            {/* <Route index element={<Navigate to={CheckoutRoutes.contactInformation} replace/>} /> */}
          </Routes>
        </PageContent>
      </Page>
    </>
  );
};

export default MainRouter;
