import React, { PropsWithChildren, Suspense } from 'react';

import PageLoading from 'core/components/styled/PageLoading';

const LazyRoute: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return <Suspense fallback={<PageLoading />}>{children}</Suspense>;
};

export default LazyRoute;
