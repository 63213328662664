import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { ApplicationState } from 'contracts/types/state';
import coreReducer from 'core/ducks/reducer';
import { reducer as agreementReducer } from 'pages/checkout/ducks/agreement';
import { reducer as billingReducer } from 'pages/checkout/ducks/billing';
import { reducer as holidaysReducer } from 'pages/checkout/ducks/holidays';
import { reducer as cartReducer } from 'pages/serviceLocation/ducks/cart';

const rootReducer = combineReducers<ApplicationState>({
  core: coreReducer,
  cart: cartReducer,
  agreements: agreementReducer,
  billing: billingReducer,
  holidays: holidaysReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
