import React, { PropsWithChildren } from 'react';

import GOOGLE_MAP_API_KEY from 'core/constants/GoogleMapApiKey';

import Script from './Script';

const GoogleMapsScript: React.FC<PropsWithChildren<ComponentProps>> = ({
  onCreate,
  onError,
  onLoad,
  children,
}) => {
  return (
    <Script
      name='googleMaps'
      isUrl
      content={`https://maps.googleapis.com/maps/api/js?libraries=places&key=${GOOGLE_MAP_API_KEY}&language=en`}
      onCreate={onCreate}
      onError={onError}
      onLoad={onLoad}
      // eslint-disable-next-line react/no-children-prop
      children={children}
    />
  );
};

interface ComponentProps {
  onCreate?: () => void;
  onError?: () => void;
  onLoad?: () => void;
}

export default GoogleMapsScript;
