import React from 'react';

import { Environments } from 'contracts/enums';
import { environment } from 'core/services/environment';

import Script from './Script';

const GoogleTagManagerScript: React.FC<ComponentProps> = () => {
  const id = (environment === Environments.local || environment === Environments.development) ? 'G-ZRYKRQPTVK' : 'G-FSNY3HS0NB';

  return (
    <Script
      name='googleTagManager1'
      isUrl
      content={`https://www.googletagmanager.com/gtag/js?id=${id}`}
      appendToHead
    >
      <Script
        name='googleTagManager2'
        content={`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${id}');`}
        appendToHead
      />
    </Script>
  );
};

interface ComponentProps {
  onCreate?: () => void;
  onError?: () => void;
  onLoad?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactNode;
}

export default GoogleTagManagerScript;
