import get from 'lodash-es/get';

import { DEFAULT_LOCALE, LOCALES, translations } from 'core/constants/locales';

const getLocale = (): string => {
  const languageLocale = navigator.language.substr(0, 2);
  return LOCALES.indexOf(languageLocale) > -1 ? languageLocale : DEFAULT_LOCALE;
};

const locale = getLocale();

const translate = (translationKey: string): string => {
  const getTranslate = get(translations[locale].default, translationKey, translationKey);
  return getTranslate;
};

export default translate;
